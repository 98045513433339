
@import '../../../assets/scss/common/variables';
@import '../../../assets/scss/common/mixins';

.delivery-modal-header {
  font-weight: bold;
  margin: 0 80px;
  border-bottom: 1px solid black;
  @include mq-s {
    margin: 0;
  }
}

.delivery-modal-body {
  margin: 0 80px;
  padding-top: 12px;
  @include mq-s {
    margin: 0;
  }
}

.delivery-modal-form {
  padding: 0 50px;
  @include mq-s {
    padding: 0;
  }
}

.delivery-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn--disabled {
  background-color: #ACABAB !important;
}
.delivery-modal-caution {
  font-size: 16px;
}

